@import "~antd/dist/antd.css";
a {
  color: var(--brand-color);
}

.paymentStatus.completed,
.bookingStatus.completed {
  color: #151515;
  border: 1px solid #151515;
}

.bookingStatus.auto_declined {
  color: rgb(224 36 36);
  border: 1px solid rgb(224 36 36);
}
